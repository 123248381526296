.socialbtns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}
.socialbtns:hover {
  .bgSC {
    path {
      fill: #a3e635;
    }
  }
}

.soLogo {
  position: absolute;
}
