@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

.outline-title {
  font-family: sans-serif;
  color: white;
  text-shadow: 1px 1px 0 #111827, -1px -1px 0 #111827, 1px -1px 0 #111827,
    -1px 1px 0 #111827, 1px 1px 0 #111827;
}

.text-shadow {
  font-family: sans-serif;
  /* color: white; */
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
    -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.outline-title2 {
  font-family: sans-serif;
  color: #111827;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
    -1px 1px 0 #fff, 1px 1px 0 #fff;
}

/* En */
@font-face {
  font-family: "CabinetGrotesk-Variable";
  src: url("../fonts/en/CabinetGrotesk-Variable.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Variable.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Variable.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Thin";
  src: url("../fonts/en/CabinetGrotesk-Thin.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Thin.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Extralight";
  src: url("../fonts/en/CabinetGrotesk-Extralight.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Extralight.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Light";
  src: url("../fonts/en/CabinetGrotesk-Light.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Light.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Regular";
  src: url("../fonts/en/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Regular.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Medium";
  src: url("../fonts/en/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Medium.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Bold";
  src: url("../fonts/en/CabinetGrotesk-Bold.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Bold.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Extrabold";
  src: url("../fonts/en/CabinetGrotesk-Extrabold.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Extrabold.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Black";
  src: url("../fonts/en/CabinetGrotesk-Black.woff2") format("woff2"),
    url("../fonts/en/CabinetGrotesk-Black.woff") format("woff"),
    url("../fonts/en/CabinetGrotesk-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
